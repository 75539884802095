<template>
  <BaseLayout v-slot>
    <Breadcrumbs />
    <section class="cart pt-8">
      <b-container>
        <span class="page-title font-weight-normal d-none d-md-block mb-8">{{
          $t("shopping_basket")
        }}</span>
        <div
          class="d-flex justify-content-between align-items-center cart-info-and-btn"
        >
          <span class="items-info font-weight-normal d-block text-blue">
            <i class="lnr lnr-cart text-warning mr-7"></i>
            {{ $t("items_in_cart", { numberOf: cartCount }) }}
          </span>
          <div class="options ml-20 d-none d-md-block">
            <b-link href="/">{{ $t("continue_shopping") }}</b-link>
            <b-button
              variant="info"
              class="text-light py-6 pr-20 pl-15"
              @click="$router.push('/checkout')"
              :disabled="cartCount == 0"
              ><i class="lnr lnr-cart mr-25"></i
              ><i class="fa fa-check-circle bg-info"></i
              >{{ $t("continue_buying") }}</b-button
            >
          </div>
        </div>
        <b-row
          v-for="cartItem of cartItems"
          :key="cartItem.id"
          class="cart-item-row position-relative"
        >
          <b-link
            href="#"
            class="d-md-none mobile-cart-item-remove"
            @click="removeItem(cartItem.id)"
            ><i class="lnr lnr-cross"></i
          ></b-link>
          <b-col md="8" class="d-flex">
            <div
              class="img"
              :style="{ backgroundImage: `url(${cartItem.product.image.url})` }"
            ></div>
            <div
              class="d-flex flex-column flex-md-row justify-content-between align-items-md-center flex-grow-1"
            >
              <div class="product text-blue pb-2 pb-md-10">
                <span class="product--name">{{ cartItem.product.name }}</span>
                <div v-if="typeof cartItem.configurable_options != 'undefined'">
                  <div
                    v-for="(option, index) of cartItem.configurable_options"
                    :key="index"
                    class="d-flex align-items-center mb-7"
                  >
                    <span class="size">{{ option.option_label }}: </span>
                    <span class="size-box">{{ option.value_label }}</span>
                    <span class="product--price text-warning d-md-none ml-20">{{
                      (cartItem.prices.price.value * cartItem.quantity).toFixed(
                        2
                      )
                    }}</span>
                  </div>
                  <b-link
                    class="change-price text-blue d-none d-md-inline-block"
                    >{{ $t("click_to_change_options") }}</b-link
                  >
                </div>
              </div>
              <div class="quantity-input d-flex ml-md-20">
                <b-link
                  href="#"
                  class="step-down-btn"
                  @click="quantityDown(cartItem.id, cartItem.quantity)"
                ></b-link>
                <input
                  type="number"
                  min="1"
                  :value="cartItem.quantity"
                  name="quantity"
                  ref="inputQuantity"
                />
                <b-link
                  href="#"
                  class="step-up-btn"
                  @click="quantityUp(cartItem.id, cartItem.quantity)"
                ></b-link>
              </div>
            </div>
          </b-col>
          <b-col
            md="4"
            class="d-none d-md-flex align-items-center justify-content-center position-relative pr-55"
          >
            <span class="product--price text-warning"
              >&euro;&nbsp;{{
                cartItem.prices.row_total_including_tax.value.toFixed(2)
              }}</span
            >
            <b-link
              href="#"
              @click="removeItem(cartItem.id)"
              class="lnr lnr-cross"
            ></b-link>
          </b-col>
        </b-row>
        <b-row class="discount-and-total">
          <b-col md="5" lg="4" class="coupon-discount">
            <span class="d-block">{{ $t("coupon_discount") }}</span>
            <form action="#" class="d-flex">
              <input
                type="text"
                class="flex-grow-1"
                name="discount-code"
                :placeholder="$t('fill_out_discount_code')"
                v-model="couponCode"
              />
              <b-button
                @click="addCoupon"
                variant="warning"
                :disabled="cartCount == 0"
                >Toepassen</b-button
              >
            </form>
          </b-col>
          <b-col
            md="6"
            xl="5"
            offset-md="1"
            offset-lg="2"
            offset-xl="3"
            class="cost"
          >
            <span class="cost-heading font-weight-normal d-block">{{
              $t("totals")
            }}</span>
            <ul class="prices">
              <li class="d-flex justify-content-between">
                <span class="name"
                  >{{ $t("shipping_cost") }}
                  {{ defaultShipping.carrier_title }}</span
                >
                <span
                  v-if="typeof defaultShipping.amount != 'undefined'"
                  class="cost ml-10"
                  >{{ defaultShipping.amount.value.toFixed(2) }}</span
                >
                <span class="time-delivery">Levertijd: 2 tot 4 werkdagen.</span>
              </li>
              <li
                class="d-flex justify-content-between"
                v-for="(discount, index) in cartTotalDiscounts"
                :key="index"
              >
                <span class="name">{{ discount.label }}</span>
                <span class="cost ml-10"
                  >- &euro; {{ discount.amount.value.toFixed(2) }}</span
                >
              </li>
              <li
                class="d-flex justify-content-between"
                v-for="(price, index) in cartTotalTaxPrices"
                :key="index"
              >
                <span class="name">{{ price.label }}</span>
                <span class="cost ml-10">{{
                  price.amount.value.toFixed(2)
                }}</span>
              </li>
            </ul>
            <div
              class="d-flex justify-content-between align-items-center mb-25"
            >
              <span class="total">{{ $t("total") }}</span>
              <span class="total-price ml-10 text-warning"
                >&euro; {{ cartTotalPrice.toFixed(2) }}</span
              >
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <b-link to="/" class="mr-15 d-md-none link-underline">{{
                $t("continue_shopping")
              }}</b-link>
              <b-button
                variant="info"
                class="text-light py-6 pr-20 pl-15"
                @click="$router.push('/checkout')"
                :disabled="cartCount == 0"
                ><i class="lnr lnr-cart mr-25"></i
                ><i class="fa fa-check-circle bg-info"></i>
                {{ $t("continue_buying") }}</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="more-products">
      <div class="container">
        <span class="section-title d-block font-weight-normal text-center"
          >Klanten bestelden ook</span
        >
        <ClientOnly>
          <MoreProductsCarousel class="pt-5" />
        </ClientOnly>
      </div>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import Breadcrumbs from "@/esf_lommel_dt1_europe/core/components//Breadcrumbs";
import MoreProductsCarousel from "@/esf_lommel_dt1_europe/core/components//MoreProductsCarousel";
import ClientOnly from "vue-client-only";

import Cart from "@storefront/core/modules/cart/mixins";
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "CartPage",
  components: {
    BaseLayout,
    Breadcrumbs,
    MoreProductsCarousel,
    ClientOnly,
  },
  mixins: [Cart],
  data() {
    return {
      noOfItems: 1,
      couponCode: "",
      prices: [
        {
          id: "price1",
          name: "Verzendkosten (Verzenden - Via PostNL of DHL)",
          cost: "€ 0,00",
          timeDelivery: "Levertijd: 2 tot 4 werkdagen.",
        },
      ],
    };
  },
  methods: {
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        Logger.debug("addCoupon", "cart.vue", retval)();
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.couponCode = "";
        }
      }
    },
    checkItemsInCart() {
      Logger.debug("itemscount", "cart", this.cartItems.length !== 0)();
      return this.cartItems.length == 0;
    },
    removeItem(id) {
      this.$store.dispatch("cart/removeCartItem", {
        id: id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";

.mobile-cart-item-remove {
  position: absolute;
  top: 65px;
  left: 55px;
  transform: translateY(-50%);
}

.page-title {
  font-size: 20px;
}

.link-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.items-info {
  font-size: 18px;
}

.options {
  a {
    text-decoration: underline;
    margin-right: 17px;
  }
}
button {
  font-size: 17px;
  position: relative;

  .fa-check-circle {
    position: absolute;
    top: 7px;
    left: 26px;
    font-size: 12px;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.cart-info-and-btn {
  margin-bottom: 13px;

  @include media("screen", ">=tablet") {
    margin-bottom: 22px;
  }
}

.cart-item-row {
  position: relative;
  padding: 21px 0 25px;

  @include media("screen", ">=tablet") {
    padding: 18px 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    height: 1px;
    background: #dfdfdf;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 15px;
    right: 15px;
    height: 1px;
    background: #dfdfdf;
    z-index: 1;
  }

  .lnr-cross {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    font-size: 17px;
  }

  + .cart-item-row {
    &:before {
      content: none;
    }
  }
}

.img {
  width: 107px;
  height: 115px;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  flex-shrink: 0;
  margin-right: 20px;
  margin-left: 15px;

  @include media("screen", ">=tablet") {
    width: 145px;
    margin-right: 5px;
  }
}

.product {
  &--name {
    display: block;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 7px;

    @include media("screen", ">=tablet") {
      margin-bottom: 12px;
    }
  }

  &--price {
    font-size: 20px;
    font-weight: 500;
  }
}

.text-blue {
  color: #0d1f47 !important;
}

.change-price {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.size-box {
  height: 25px;
  border: 1px solid #000;
  text-align: center;
  padding: 4px;
  line-height: 1;
  font-weight: normal;
  margin-left: 13px;
}

.discount-and-total {
  padding-top: 13px;

  @include media("screen", ">=tablet") {
    padding-top: 32px;
  }
}

.coupon-discount {
  padding-bottom: 33px;

  @include media("screen", ">=tablet") {
    padding-bottom: 0;
  }
  span {
    font-size: 17px;
    font-weight: normal;
    padding-bottom: 15px;
    border-bottom: 1px solid #dfdfdf;
  }

  form {
    padding-top: 18px;

    .btn {
      padding: 7px 18px;
      font-size: 17px;
      font-weight: normal;
      color: white;
    }

    input[type="text"] {
      border-radius: 3px 0 0 3px;
      border: 1px solid #000;
      border-right: none;
      padding: 8px 13px 8px;
      font-size: 13px;
      line-height: 1;
    }
  }
}

.cost-heading {
  font-size: 18px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 13px;
}

.prices {
  list-style: none;
  padding-left: 0;
  padding-top: 14px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 7px;

  li {
    flex-wrap: wrap;
    line-height: 1.3;

    + li {
      margin-top: 5px;
    }
  }

  .cost {
    font-weight: 500;
  }

  .time-delivery {
    color: $green;
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }
}

.total {
  font-size: 20px;
  font-weight: normal;
}

.total-price {
  font-size: 20px;
  font-weight: 500;
}

.more-products {
  padding-top: 42px;
  padding-bottom: 7px;

  @include media("screen", ">=tablet") {
    padding-top: 90px;
    padding-bottom: 0;
  }

  .section-title {
    font-size: 23px;
  }
}
.quantity-input {
  width: 105px;
  height: 39px;
  flex-shrink: 0;
  border: 1px solid #000;

  input[type="number"] {
    border: none;
    width: 31px;
    padding: 3px 0;
    margin: 0;
    font-size: 17px;
    font-family: "Work Sans";
    text-align: center;
    font-weight: 600;
    outline: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .step-down-btn,
  .step-up-btn {
    display: block;
    position: relative;
    width: 37px;
    height: 100%;
    flex-shrink: 0;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 13px;
      height: 1px;
      background: #000;
    }
  }

  .step-up-btn {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 13px;
      background: #000;
    }
  }
}
</style>
